import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Suspense, lazy } from 'react';
import Simple_Signup from './Pages/Simple_Signup';
import Credit_Signup from './Pages/Credit_Signup';
import WalletLogin from './Pages/WalletLogin';
import WalletSignup from './Pages/WalletSignup';
import SubscribeLogin from './Pages/SubscribeLogin';
import PaymentForm from './Pages/PaymentCall';

//these are the routing of the
const Loader = lazy(() => import('./Components/Loader'));
const Home = lazy(() => import('./Pages/Home'));
const Premium_domain = lazy(() => import('./Pages/Premium_domain'));
const Learn = lazy(() => import('./Pages/Learn'));
const Support = lazy(() => import('./Pages/Support'));
const SearchDomain = lazy(() => import('./Pages/SearchDomain'));
const Learn_query = lazy(() => import('./Pages/Learn_query'));
const Domain = lazy(() => import('./Pages/Domain'));
const CheckAvailability = lazy(() => import('./Pages/CheckAvailability'));
const Signup = lazy(() => import('./Pages/Signup'));
const Login = lazy(() => import('./Pages/Login'));
const ForgetScreen = lazy(() => import('./Pages/ForgetScreen'));
const NewPassword = lazy(() => import('./Pages/NewPassword'));
const ChangePass = lazy(() => import('./Pages/ChangePass'));
const SubscriptionPackage = lazy(() => import('./Pages/SubscriptionPackage'));
const UpdateCreditCard = lazy(() => import('./Pages/UpdateCreditCard'));
const ViewInvoices = lazy(() => import('./Pages/ViewInvoices'));
const CancelSubscription = lazy(() => import('./Pages/CancelSubscription'));
const ViewPayment = lazy(() => import('./Pages/ViewPayment'));
const PremiumContent = lazy(() => import('./Pages/PremiumContent'));
const Article = lazy(() => import('./Pages/Article'));
const Video = lazy(() => import('./Pages/Video'));
const IpfsPage = lazy(() => import('./Pages/IpfsPage'));
const ArticlesDetails = lazy(() => import('./Pages/ArticlesDetails'));
const Protected = lazy(() => import('./Components/Protected'));
const UserSubcriptionPage = lazy(() => import('./Pages/UserSubcriptionPage'));

function App() {

  const API = process.env.REACT_APP_API;
  // const API =  'http://192.168.25.51:8521';
  // const API = 'https://dev2.cryscampus.com';
  // console.log('Api ------- ',API)
  localStorage.setItem('API',API)
  const user = sessionStorage.getItem('User')


  return (
    <Suspense fallback={<div><Loader/></div>}>
      <BrowserRouter>
        <Routes> 
          <Route path='/home' element={<Home />} />
          {/* <Route path='/signup' element={<Signup />} /> */}
          <Route path='/SubscribeLogin' element={<SubscribeLogin />} />
          <Route path='/Signup' element={<Simple_Signup />} />
          <Route path='/CreditSignup' element={<Credit_Signup />} />
          <Route path='/WalletLogin' element={<WalletLogin />} />
          <Route path='/WalletSignup' element={<WalletSignup />} />
          <Route path='/' element={<Home />} />
          <Route path='/Test' element={<PaymentForm />} />
          <Route path='/login' element={<Login />} />
          {/* <Route path='/auth/password_reset/confirm' element={<NewPassword />} /> */}
          <Route path='/auth/password_reset/confirm' element={<NewPassword />} />
          <Route path='/forgetpassword' element={<ForgetScreen />} />
          <Route path='/premium' element={<Premium_domain />} />
          <Route path='/learn_query/:id' element={<Learn_query />} />
          <Route path='/learn' element={<Learn />} />
          <Route path='/support' element={<Support />} />
          <Route path='/search/:domain_search' element={<SearchDomain />} />
          <Route path='/domains' element={<Domain />} />
          <Route path='/subscription' element={<SubscriptionPackage/>} />
          <Route path='/premiumdomain/:id' element={<CheckAvailability />} />
          <Route path='/updatecard' element={<Protected Component={UpdateCreditCard} />} />
          <Route path='/settings' element={<Protected Component={UpdateCreditCard} />} />
          <Route path='/changepassword' element={<Protected Component={ChangePass} />} />
          <Route path='/viewinvoices' element={<Protected Component={ViewInvoices} />} />
          <Route path='/viewpayment' element={<Protected Component={ViewPayment} />} />
          <Route path='/cancelsub' element={<Protected Component={CancelSubscription} />} />
          <Route path='/premiumcontent' element={<Protected Component={PremiumContent} />} />
          <Route path='/premiumcontent' element={<Protected Component={PremiumContent} />} />
          <Route path='/article' element={<Protected Component={Article} />} />
          <Route path='/video' element={<Protected Component={Video} />} />
          <Route path='/add_domain_content' element={<Protected Component={IpfsPage} />} />
          <Route path='/articledetails/:title' element={<Protected Component={ArticlesDetails} />} />
          <Route path='/User_Subcription' element={<Protected Component={UserSubcriptionPage} />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
