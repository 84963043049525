import React from 'react'
import WalletConnectProvider from '@walletconnect/web3-provider';
import { BrowserProvider, ethers } from 'ethers';
import Web3Modal from 'web3modal';
import {providerOptions} from '../Wallet/providerOptions'
import erc20abi from '../Wallet/contract_abi.json';

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions // required
});

const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
export const contract_Address="0x878187b6403111426c87fDD496fD1BA27820dF5b";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
export const Contract_function = new web3.eth.Contract(
    erc20abi,
    contract_Address
);

export async function connectWallet() {

  try {
    const provider = await web3Modal.connect();
    const web3Provider =await new BrowserProvider(provider);
    const signer =await  web3Provider.getSigner();
    const address=await signer.getAddress();
    const network=await web3Provider.getNetwork();
    let network_name="";
    const startSubAddress = address.substring(0, 10); // Extracts the first 4 characters
    const endSubAddress = address.substring(address.length - 3);
    const extractAddress = startSubAddress+' ······ '+endSubAddress
    // 1: Mainnet
    // 5: Goerli test network
    // 42: Kovan test network
    // 56: Binance Smart Chain (BSC) mainnet
    // 97: Binance Smart Chain (BSC) testnet
    // 137: Polygon (previously Matic) mainnet
    // 80001: Polygon (previously Matic) Mumbai testnet
    if(network.chainId===1n){
      network_name='Mainnet'
    }
    else if(network.chainId===5n){
      network_name='Goerli network'
    }
    else if(network.chainId===137n){
      network_name='Polygon mainnet'
    }
    else if(network.chainId===80001n){
      network_name='Polygon testnet'
    }

    // console.log('Connected to wallet:', await web3Provider);
    // console.log(' wallet address:', address);
    // console.log(' wallet network:',await  network.name)
    // console.log(' wallet chinID:',await  network.chainId,typeof network.chainId,network.chainId ===5n )
    // console.log(' network_name:',network_name)
    sessionStorage.setItem('wallet_address',address)
    window.dispatchEvent(new Event("storage"));
    return {address,network_name,extractAddress};
  } catch (error) {
    console.error(error);
  }
}

export async function disconnectWallet() {

  await web3Modal.clearCachedProvider();
  sessionStorage.removeItem('wallet_address')
  window.dispatchEvent(new Event("storage"));
  

};

export const getCurrentWalletConnected = async () => {
  try {
    const addressArray = await window.ethereum.request({
      method: "eth_accounts",
    });
    if (addressArray.length > 0) {
      return {
        address: addressArray[0],
        status: "",
       
      };
    } else {
      return {
        address: "",
        status: "",
      };
    }
  } catch (err) {
    if (err.code === 4001) {
      return {
        address: "",
        status: "😥 User Reject Connection Request Retry To Connect" ,
        error:'😥 User Reject Connection Request Retry To Connect'
      };
    }
    else{
      return {
        address: "",
        status: "😥 " + err.message,
        error:'😥   Please Install Wallet'

      };
    }
    
  }
};

//miniting function which contain metadate of img url name and description
// export const MintNFT = async (address,input,minted_value,contractAddress,contractAbi,contract_type,arr_address,white_proof,myleaf,pinata_uri) => {

//   try {
//   //if any form input field is empty return false
//   console.log('wallet_address',address)
//   console.log('contractAddress',contractAddress);
//   console.log('white_proof',white_proof);
//   console.log('myleaf',myleaf);
//   console.log('pinata_uri',pinata_uri);
//   console.log('contract_type',contract_type);
  
  
          

//   // getProof(address);

//   let new_minted_value=minted_value+1;

//    arr_mint=[];
//    arr_index=[];

//   // let nfturi =  window.contract.methods.uri(1);
//   for(let i=0;i<input;i++){
    
//     arr_mint.push(new_minted_value+i);
    
//     arr_index.push(1);
//   }

//   var ints = arr_mint;
//   var hexstr = [];
//    numberArray = [];
//   for(var i=0; i < ints.length; i++) {
//       hexstr.push("0x"+ints[i].toString(16)) ;
//   }


//   for (var i = 0; i < hexstr.length; i++) {
//     numberArray.push(hexstr[i].replace(/['"]+/g, ''));
    
// }

//     window.contract = await new web3.eth.Contract(contractAbi, contractAddress);

//     console.log('window.contract',window.contract)
//     console.log('window.contract',window.contract.methods)
    


    
 
//      // let nftPrice = await window.contract.methods.viewPrice().call();


//     //  nftPrice = await  window.contract.methods.getPrice(white_proof,myleaf).call();
//      nftPrice = await  window.contract.methods.viewPrice().call();
//     // nftPrice=90000000000000;
//     console.log(nftPrice)
   
//     localStorage.setItem('price',nftPrice)

//   let mint_quan=input;
//   let gas_price=mint_quan * nftPrice;
//   let hex_price = `0x${parseInt(gas_price, 10).toString(16).toUpperCase()}`;
  


//     //passing ABI and contract address 
//       //encodeABI() include: preparing a smart contract transaction for a multisignature wallet
//       // const nftPrice = window.contract.methods.viewPrice()._ethAccounts.setProvider.length
//       // const arrProto = Object.getPrototypeOf(nftPrice)

 
//       let ContractObj="";

//       console.log('contract_type',contract_type)
//       if(contract_type==="721"){
//         // Contract_Type=window.contract.methods.batchMintNFT(input,white_proof,myleaf).encodeABI()
//         console.log('window.contract.methods.mintToken(pinata_uri).encodeABI()',pinata_uri)
//         ContractObj=window.contract.methods.mintToken(pinata_uri).encodeABI()

//       }
//       if(contract_type==="1155"){
      
//          ContractObj=window.contract.methods.batchMintNFT(numberArray,arr_index,white_proof,myleaf).encodeABI()
//         // Contract_Type=window.contract.methods.mintToken(pinata_uri).encodeABI()

//       }




//   const transactionParameters = {
//     to: contractAddress, // Required except during contract publications.
//     // from: window.ethereum.selectedAddress, // must match user's active address.
//     from: address, // must match user's active address.
//     value: hex_price,
//     // data: window.contract.methods.batchMintNFT(numberArray,arr_index).encodeABI(),
//     data: ContractObj,
//   };

//   var txHash=""
//   // trasaction request with parameters(to,from and data)
//   if(typeof window.ethereum !== 'undefined' ){
   
//      txHash = await window.ethereum.request({
//       method: "eth_sendTransaction",
//       params: [transactionParameters],
//     })
//   }
//   else{ 
//     const transactionParameters1 = {
//       from: address,
//       to: contractAddress,
//       value: '10000000000',
//       gas: '210000',
//     };
  

//    let  txHash_block = await web3.eth.sendTransaction(transactionParameters1, function (error, transactionHash) {
//       if (error) {
//         alert('error1')
//         alert(error)
//         console.error(error);
//       } else {
//         alert('transactionHash')
//         alert(transactionHash)
//         console.log("Transaction hash: " + transactionHash);
//       }
//     });

//     txHash=txHash_block.transactionHash 
   
//   }



  


   
//    var token_id;
//    localStorage.setItem('transaction_hash',txHash);
//       GetReciepts()
 
//     var RecInt ;
//     async function GetReciepts(){
    
//       const drd=txHash
    
//       console.log('recipt',drd)
     
//       if(drd === null){
        
//         // RecInt = setInterval(GetReciepts, 4000);
//         setTimeout(()=>{
//           GetReciepts()
//         },4000)
       
//       }
//       else {
       
//         console.log('here2',drd.blockNumber)
//         token_id= drd.blockNumber
//         localStorage.setItem('hash_code',token_id);
//         return token_id
//         // clearInterval(RecInt);
//       }

     
    
     
     
     
      
//     }
 
    
//     return {
//       success: true,
//       status:
//         `Transaction:- ${txHash}`,
//       hash_code:txHash,

//     };
//   } 
//   catch (err) {
   
    
//     console.log('err.code',err)
//     if (err.code === 4001) {
//       return {
//         success: false,
//         address: "",
//         status: "😥 User Reject Connection Request Retry To Connect" 
//       };
//     }
//     else{
//       return {
//         success: false,
//         status: "😥 Something went wrong: " + err.message,
//         err:"Something went wrong: " + err.message,
//       };
//     }
    
//   }
// };
