import React from 'react'
import { CardElement, useStripe, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';

function PaymentCall() {
    return (
            <PaymentForm  />
      );
}

export default PaymentCall
