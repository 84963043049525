import { Container, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Header from "../Components/Header";
import "../Sass/Login.scss";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { PuffLoader } from "react-spinners";
import { FaUserAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setPremiumMemberStatus } from "../cirl_redux/cirlSlice";
import CreditCardForm from "../Components/CreditCardForm";
import UpdateCreditCardForm from "../Components/UpdateCreditCardForm";

function SubscribeLogin() {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [state_loader, setState_Loader] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [rememberedUsers, setRememberedUsers] = useState([]);
  const Api = localStorage.getItem("API");
  const Token = sessionStorage.getItem("Token");
  const remember = localStorage.getItem("remember");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const planID = sessionStorage.getItem("planeID");
  const [show_modal,set_show_modal]=useState(false)

  useEffect(() => {
    const storedUsers =
      JSON.parse(localStorage.getItem("rememberedUsers")) || [];
    setRememberedUsers(storedUsers);
  }, []);

  useEffect(() => {
    const selectedUser = rememberedUsers.find((user) => user.email === email);
    console.log("selectedUser", selectedUser);
    if (selectedUser) {
      setPassword(selectedUser.password);
      setRememberMe(true);
    } else {
      setPassword("");
      setRememberMe(false);
    }
  }, [email, rememberedUsers]);

  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
    setPassword(evnt.target.value);
  };
  const togglePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };
 
  const HandleSubmit = (event) => {
    event.preventDefault();
    setState_Loader(true);
    const data = new FormData(event.target);

    console.log(
      "login data ----- ",
      data.get("username"),
      data.get("password")
    );

    const body = {
      username: data.get("username"),
      password: data.get("password"),
    };
    axios
      .post(`${Api}/auth/signin/`, body)
      .then((res) => {
        console.log("Login Api Response ---- ", res.data.access);
        sessionStorage.setItem("Token",res.data.access);
        sessionStorage.setItem("RefreshToken", res.data.refresh);
        sessionStorage.setItem("is_premium", res.data.is_premium);
        dispatch(setPremiumMemberStatus(res.data.is_premium));


        if (res.data.error === "Invalid Password") {
          NotificationManager.error(res.data.error);
          setState_Loader(false);
        } else if (res.data.error === "Invalid email or username") {
          NotificationManager.error(res.data.error);
          setState_Loader(false);
        }
         else {
          axios
          .get(`${Api}/payment/check_customer/`, {
            headers: {
              Authorization: `Bearer ${res.data.access}`,
            },
          })
          .then((res) => {
              sessionStorage.setItem("LoginUser", true);

            if(res.data.status === true){
              if(res.data.message==='You already have an active Membership'){
                NotificationManager.success("Login Successfully");
                setState_Loader(false);
                event.target.reset();
                sessionStorage.setItem("LoginUser", true);
                sessionStorage.setItem("is_premium", res.data.is_premium);
                dispatch(setPremiumMemberStatus(res.data.is_premium));
                navigate("/premiumcontent");
              }
              // console.log("planID", planID, typeof planID);
              // if (planID !== "" || planID !== null || planID !== undefined) {
             
              //    sessionStorage.setItem("LoginUser", true);
            
              //   const body = {
              //     plan_id: planID,
              //   };
              //   axios
              //     .post(`${Api}/membership/upgrade_subscription/`, body, {
              //       headers: {
              //         Authorization: `Bearer ${sessionStorage.getItem("Token")}`,
              //       },
              //     })
              //     .then((res) => {
    
              //       if (res.data.error) {
              //         NotificationManager.error("Some thing went wrong");
              //         dispatch(setPremiumMemberStatus(false));  
              //         setState_Loader(false);
              //         event.target.reset();
              //       } else if (res.data.status === false) {
              //         NotificationManager.error("Some thing went wrong");
              //         dispatch(setPremiumMemberStatus(false));
              //         setState_Loader(false);
              //         event.target.reset();
              //       } else {
              //         NotificationManager.success("Subscription successfull");
              //         sessionStorage.setItem("is_premium", "true");
              //         navigate("/premiumcontent");
              //         dispatch(setPremiumMemberStatus(true));
              //         setState_Loader(false);
              //         event.target.reset();
              //         if (rememberMe) {
              //           const updatedUsers = [...rememberedUsers];
              //           const existingUser = updatedUsers.find(
              //             (user) => user.email === email
              //           );
              //           if (existingUser) {
              //             existingUser.password = password;
              //           } else {
              //             updatedUsers.push({ email, password });
              //           }
              //           localStorage.setItem(
              //             "rememberedUsers",
              //             JSON.stringify(updatedUsers)
              //           );
              //         } else {
              //           const updatedUsers = rememberedUsers.filter(
              //             (user) => user.email !== email
              //           );
              //           localStorage.setItem(
              //             "rememberedUsers",
              //             JSON.stringify(updatedUsers)
              //           );
              //         }
              //       }
              //     })
              //     .catch((err) => {
              //       setState_Loader(false);
              //       NotificationManager.error("Something Went Wrong");
              //     });
              // } else {
              //   NotificationManager.success("Login Successfully");
              //   setState_Loader(false);
              //   event.target.reset();
              //   sessionStorage.setItem("LoginUser", true);
              //   sessionStorage.setItem("Token", res.data.access);
              //   sessionStorage.setItem("RefreshToken", res.data.refresh);
              //   sessionStorage.setItem("is_premium", res.data.is_premium);
              //   dispatch(setPremiumMemberStatus(res.data.is_premium));
              //   if (rememberMe) {
              //     const updatedUsers = [...rememberedUsers];
              //     const existingUser = updatedUsers.find(
              //       (user) => user.email === email
              //     );
              //     if (existingUser) {
              //       existingUser.password = password;
              //     } else {
              //       updatedUsers.push({ email, password });
              //     }
              //     localStorage.setItem(
              //       "rememberedUsers",
              //       JSON.stringify(updatedUsers)
              //     );
              //   } else {
              //     const updatedUsers = rememberedUsers.filter(
              //       (user) => user.email !== email
              //     );
              //     localStorage.setItem(
              //       "rememberedUsers",
              //       JSON.stringify(updatedUsers)
              //     );
              //   }
              // }
            }
            else{
              set_show_modal(true)
            }
          })
     
        }
      })
      .catch((error) => {
        console.log("Login Api Error ----- ", error);
        setState_Loader(false);
      });
  };

  const HandleNavigate = () => {
    navigate("/forgetpassword");
  };

  const HandleSignUp = () => {
    navigate("/signup");
  };

  const HandleGuestLogin = () => {
    navigate("/home");
  };

  const HandleCheckbox = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <>
      <Header loginHeader={false} />
      <Container>
        {!show_modal?
        <Form onSubmit={HandleSubmit} className="login-container">
        <h1 className="login-h1">Login your account</h1>
        <p className="login-p">Please enter your details</p>
        <div className="mt-3" />

        <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
          <Form.Label className="login-formlabel">Email Address</Form.Label>
          <Form.Control
            required
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            autoComplete="username"
            name="username"
            type="email"
            placeholder="Email Address"
          />
        </Form.Group>

        <Form.Group className="mb-3 mt-3" controlId="formBasicPassword">
          <Form.Label className="login-formlabel">Password</Form.Label>
          <div className="login-password-div">
            <Form.Control
              required
              name="password"
              placeholder="Password"
              type={passwordType}
              value={password}
              autoComplete="current-password"
              onChange={handlePasswordChange}
            />

            {passwordType == "password" ? (
              <FiEye onClick={togglePassword} />
            ) : (
              <FiEyeOff onClick={togglePassword} />
            )}
          </div>
        </Form.Group>

        <div className="login-remember-maindiv">
          <Form.Group className="mt-3" controlId="formBasicCheckbox">
            {/* <div className='login-remember'>
      <input type="checkbox" onChange={HandleCheckbox} {state_checked == 'true' ? checked : null } />
      <p className='login-remember-p'>Remember me</p>
      </div> */}
            {rememberMe === true ? (
              <Form.Check
                onChange={HandleCheckbox}
                checked
                type="checkbox"
                label="Remember me"
              />
            ) : (
              <Form.Check
                onChange={HandleCheckbox}
                type="checkbox"
                label="Remember me"
              />
            )}
          </Form.Group>
          <p onClick={HandleNavigate} className="login-forget">
            Forgot Password?
          </p>
        </div>

        <Button
          className="mt-5 mb-3"
          style={{ display: "flex", justifyContent: "center" }}
          variant="primary"
          type="submit"
        >
          {state_loader ? <PuffLoader color="#ffffff" size={24} /> : "Login"}
        </Button>

        <p className="login-or">OR</p>

        <Button
          className=" mb-5 login-sign-btn"
          type="submit"
          onClick={HandleSignUp}
        >
          Don't have an account? Sign Up
        </Button>
      </Form>
      :<div style={{padding:'9em 20em 0 20em'}}><CreditCardForm/></div>}
        
      </Container>
    </>
  );
}

export default SubscribeLogin;
