import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Header from "../Components/Header";
import "../Sass/Signup.scss";
import { FiEye, FiEyeOff, FiCheckCircle, FiXCircle } from "react-icons/fi";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { setPremiumMemberStatus } from "../cirl_redux/cirlSlice";
import { useDispatch } from "react-redux";
import { PuffLoader } from "react-spinners";

function WalletLogin() {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordInput1, setPasswordInput1] = useState("");
  const walletAddress = sessionStorage.getItem("wallet_address");

  const Api = localStorage.getItem("API");
  const navigate = useNavigate();
  const [userEmail, setuserEmail] = useState("");
  const [userWallet, setuserWallet] = useState("");
  const [userName, setuserName] = useState("");
  const dispatch = useDispatch();
  const {state} = useLocation();
  const [state_BackNavigate, setState_BackNavigate] = useState(state.state);
  const [state_loading, setState_Loading] = useState(false);
  console.log('Back to Navigate ----- ', state_BackNavigate);
  // sessionStorage.setItem('back_navigate',state_BackNavigate)

  useEffect(() => {
    if (walletAddress) {
      axios
        .get(
          `${Api}/user_management/check_user_wallet_address/?wallet_address=${walletAddress}`
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.is_user === true) {

            sessionStorage.setItem("is_logged_in",res.data.is_logged_in)
            sessionStorage.setItem("wallet_address", res.data.wallet_address);
            setuserEmail(res.data.email);
            setuserWallet(res.data.wallet_address);
            setuserName(res.data.full_name);
            sessionStorage.setItem('userName',res.data.full_name)
          } else if (res.data.is_user === false) {
            // sessionStorage.setItem('wallet_address',props)
            navigate("/WalletSignup",{state:{state : state_BackNavigate}});
          }
        })
        .catch((error) => {
          // console.log('Login Api Error ----- ',error)
        });
    }
  }, []);
  useEffect(() => {
    setuserWallet(sessionStorage.getItem("wallet_address"));
  }, []);
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
    console.log("Password field ----- ", evnt.target.value);
    onInputChange(evnt);
  };
  const togglePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const handlePasswordChange1 = (evnt) => {
    setPasswordInput1(evnt.target.value);
    onInputChange(evnt);
    console.log("Confirm Password field ----- ", evnt.target.value);
  };
  const togglePassword1 = () => {
    passwordType1 === "password"
      ? setPasswordType1("text")
      : setPasswordType1("password");
  };

  const HandleSubmit = (event) => {
    setState_Loading(true)
    event.preventDefault();
    const data = new FormData(event.target);
    console.log(
      "Sign up data ----- ",
      data.get("first"),
      data.get("last"),
      data.get("email"),
      data.get("username"),
      data.get("password"),
      data.get("confirmPassword")
    );

    const body = {
      username: userEmail,
      password: data.get("password"),
      wallet_address: userWallet,
      wallet_type: "MetaMask",
    };
    axios
      .post(`${Api}/auth/wallet_signin/`, body)
      .then((res) => {
        console.log("Signup Api Response ---- ", res.data);
        // NotificationManager.success(res.data.error)
        if (res.data.status == false) {
          if (
            res.data.error == "User Already Exists, Try Adding other username"
          ) {
            NotificationManager.error("This Username is Already Taken");
            setTimeout(()=>{
              setState_Loading(false)
            },4000)
            
          } else if (res.data.error == "Email must be Unique.") {
            NotificationManager.error(res.data.error);
            setTimeout(()=>{
              setState_Loading(false)
            },4000)
          } else if (
            res.data.error ==
            "Password must contain at least one uppercase letter.."
          ) {
            NotificationManager.error(res.data.error);
            setTimeout(()=>{
              setState_Loading(false)
            },4000)
          } else if (
            res.data.error ==
            "Password must contain at least one unique character."
          ) {
            NotificationManager.error(res.data.error);
            setTimeout(()=>{
              setState_Loading(false)
            },4000)
          } else {
            // event.target.reset(),
            NotificationManager.error(res.data.error);
            setTimeout(()=>{
              setState_Loading(false)
            },4000)
          }
        } else if (res.data.status == true) {
          NotificationManager.success("Login Successfully");

          setTimeout(()=>{
            setState_Loading(false)
          },4000)
          sessionStorage.setItem("LoginUser", true);
          sessionStorage.setItem("Token", res.data.access);
          sessionStorage.setItem("is_logged_in",true)
          sessionStorage.setItem("RefreshToken", res.data.refresh);
          sessionStorage.setItem("is_premium", res.data.is_premium);
          dispatch(setPremiumMemberStatus(res.data.is_premium));

          axios
            .get(`${Api}/user_management/check_user_sold_domains/`, {
              headers: {
                Authorization: `Bearer ${res.data.access}`,
              },
            })
            .then((res) => {
              console.log("res", res);
              if (res.data.status === true) {
                // navigate("/domains");
                navigate( state_BackNavigate );
                
              } else {
                // navigate("/premium");
                navigate( state_BackNavigate );
              }
            })
            .catch(function (error) {
              NotificationManager.error(error.message);
              navigate(state_BackNavigate);
              // navigate("/premium");
            });
        }
      })
      .catch((error) => {
        console.log("Signup Api Error ----- ", error);
        NotificationManager.error("Something Went Wrong");
      });
  };

  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    console.log("Name ---- ", name, value);
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            // stateObj[name] = "Please enter Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            // stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const HandleLogin = () => {
    navigate("/WalletSignup",{state});
  };

  return (
    <>
      <Header loginHeader={false} navigate_back={state_BackNavigate} />
      <Container>
        <Form onSubmit={HandleSubmit} className="signup-container">
          <h1 className="signup-h1">Sign In</h1>
          <p className="signup-p">Please enter your details</p>
          <Form.Group className="mb-1 mt-4" controlId="formBasicEmail">
            <Form.Label className="signup-formlabel">Wallet Address</Form.Label>
            <Form.Control
              required
              name="walletAddress"
              type="text"
              value={userWallet}
              disabled={true}
              readOnly
              placeholder="Wallet Address"
            />
          </Form.Group>
          <Form.Group className="mb-1 mt-2" controlId="formBasicEmail">
            <Form.Label className="signup-formlabel">Email Address</Form.Label>
            <Form.Control
              required
              className="signup-pass-placeholder"
              name="email"
              onChange={(e) => {
                setuserEmail(e.target.value);
              }}
              disabled={true}
              readOnly
              value={userEmail}
              type="email"
              placeholder="Email Address"
            />
          </Form.Group>

          <Form.Group className="mb-1 mt-2" controlId="formBasicPassword">
            <Form.Label className="signup-formlabel">Password</Form.Label>
            <div className="signup-password-div">
              <Form.Control
                required
                className="signup-pass-placeholder"
                title="Password must contain one special character, one Uppercase and at least 8 characters length"
                name="password"
                placeholder="Password"
                type={passwordType}
                defaultValue={input.password}
                onChange={handlePasswordChange}
                onBlur={validateInput}
                pattern="(?=.*[a-z])(?=.*[A-Z]).{8,}"
              />

              {passwordType == "password" ? (
                <FiEye onClick={togglePassword} />
              ) : (
                <FiEyeOff onClick={togglePassword} />
              )}
            </div>
            <div className="signup-form-label-div">
              <Form.Label className="signup-form-label">
                Password must contain one special character, one Uppercase and
                at least 8 characters length
              </Form.Label>{" "}
              <br />
              {error.password && (
                <span style={{ color: "red" }}>{error.password}</span>
              )}
            </div>
            {/* {state_Passmatch == true ? <p>Password match</p> : <p>Password must be same</p>} */}
          </Form.Group>

          <Form.Group className="mt-1" controlId="formBasicCheckbox">
            <Form.Check
              required
              type="checkbox"
              label="Accept marketing communications"
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-1" controlId="formBasicCheckbox">
            <div className="signup-tc-div">
              <Form.Check required type="checkbox" label="" />
              <p>
                I accept{" "}
                <span className="signup-termsconditions">
                  {" "}
                  Terms & conditions{" "}
                </span>{" "}
              </p>
            </div>
          </Form.Group>
          {
            state_loading ? (
              <Button 
              variant="primary" 
              type="submit" disabled 
              style={{ display: "flex", justifyContent: "center" }}
              >
                <PuffLoader color="#ffffff" size={24} />
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Sign In
              </Button>
            )
          }
          
          <p className="mt-3 mb-2 signup-or">OR</p>
          <Button
            onClick={HandleLogin}
            className="mt-3 mb-5 signup-sign-btn"
            variant="primary"
            type="submit"
          >
            Don't have an account? Sign Up
          </Button>
        </Form>
      </Container>
    </>
  );
}

export default WalletLogin;
