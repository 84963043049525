import React, { useEffect, useLayoutEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import InputMask from "react-input-mask";
import { FaCreditCard, FaRegCreditCard } from "react-icons/fa";
import "../Sass/CreditCardForm.scss";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { PuffLoader, PulseLoader } from "react-spinners";
import card_img from "../Assets/Images/card_img.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getPremiumState,
  setPremiumMemberStatus,
} from "../cirl_redux/cirlSlice";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

function CreditCardForm({ payment_success }) {
  const [cardEmail, setcardEmail] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const Api = localStorage.getItem("API");
  const token_access = sessionStorage.getItem("Token");
  const [loading, setLoading] = useState(false);
  var userLoginEmail = "";
  const Dispatch = useDispatch();
  const Pakage_id = useSelector((state) => state.cirl.subscription_plane_id);
const navigate=useNavigate()

const [stripe, setStripe] = useState(null);
const [cardNumberElement, setCardNumberElement] = useState(null);
const [cardExpiryElement, setCardExpiryElement] = useState(null);
const [cardCvcElement, setCardCvcElement] = useState(null);


const stripePromise = loadStripe('pk_test_51MGeSgLN0C8PbbSaxqzV70km0tvzZeZ29amk7P6SMlHqS9H84fLYinRLvYHDle3d5zoMlFrUzdWofuqXfMsI0zAT00f3TmvCkB');

useLayoutEffect(() => {
  setLoading(true);
  const initializeStripe = async () => {
    const stripeInstance = await stripePromise;
    setStripe(stripeInstance);

    // Create the Stripe Elements
    const elements = stripeInstance.elements();

    const cardNumber = elements.create('cardNumber');
    cardNumber.mount('#card-number');
    setCardNumberElement(cardNumber);

    const cardExpiry = elements.create('cardExpiry');
    cardExpiry.mount('#card-expiry');
    setCardExpiryElement(cardExpiry);

    const cardCvc = elements.create('cardCvc');
    cardCvc.mount('#card-cvc');
    setCardCvcElement(cardCvc);
  };

  initializeStripe();
  setLoading(false);
}, []);

const handleCardFormSubmit = async (event) => {
  event.preventDefault();
  setLoading(true);
  if (stripe && cardNumberElement && cardExpiryElement && cardCvcElement) {
      stripe.createToken(cardNumberElement).then(function(result) {
        if(result.token.id){
          const card_tok=result.token.id
          console.log(card_tok)
          get_user_email(card_tok)
        }
        else{
          NotificationManager.error('some thing went wrong');
          setLoading(false);
        }
    
     
       
    }).catch((error)=>{
        console.log('Payment method error:', error);
        setLoading(false);
        if(error == "TypeError: Cannot read properties of undefined (reading 'id')"){
          NotificationManager.error("please enter correct card details");
        }
        else{
          NotificationManager.error(error.message);
        }
    })
  }
};
  const handleCardemail = (event) => {
    setcardEmail(event.target.value);
  };
  const handleCardNumberChange = (event) => {
    const cardNumberWithoutSpaces = event.target.value.replace(/\s/g, "");
    setCardNumber(cardNumberWithoutSpaces);
  };

  const handleExpiryDateChange = (event) => {
    setExpiryDate(event.target.value);
  };

  const handleCvvChange = (event) => {
    setCvv(event.target.value);
  };
  const get_user_email = (card_tok) => {
    console.log("Pakage_id", Pakage_id);
    axios
      .get(`${Api}/user_management/get_user_email/`, {
        headers: {
          Authorization: `Bearer ${token_access}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          userLoginEmail = res.data.email;
          handleSubmit(userLoginEmail,card_tok)
        } else {
          NotificationManager.error("some thing went wrong");
          setLoading(false);
        }
      });
  };
  const handleSubmit = (userLoginEmail,card_tok) => {
    console.log("Pakage_id", Pakage_id);
   
          const member_data = {
            card_token: card_tok,
            email: userLoginEmail,
            plan: Pakage_id,
          };
          axios
            .post(`${Api}/membership/create_memebership/`, member_data, {
              headers: {
                Authorization: `Bearer ${token_access}`,
              },
            })
            .then((res) => {
              console.log("create_memebership", res.data);

              if (res.data.error) {
                NotificationManager.error("Some thing went wrong");
                Dispatch(setPremiumMemberStatus(false));
                setLoading(false);
              } else if (res.data.status === false) {
                NotificationManager.error("Some thing went wrong");
                Dispatch(setPremiumMemberStatus(false));
                setLoading(false);
              } else {
                NotificationManager.success("Payment successfull");
                sessionStorage.setItem('CheckSubscription',true)
                sessionStorage.setItem("is_premium", "true");
                navigate('/premiumcontent')
                sessionStorage.setItem("LoginUser", true);
                Dispatch(setPremiumMemberStatus(true));
                payment_success(false);
                setLoading(false);
              }
            });
  };
  return (
    <div className="credit-card-form">
               <div className="credit-card-header">
                  <img src={card_img} alt="Credit Card" className="credit-card-img" />
                </div>
                <div className="text-center">
                  <h3 className="credit-card-heading">Credit Card Details</h3>
                </div>
                <Form onSubmit={handleCardFormSubmit} className="creditcard-form">
                <Form.Group controlId="cardNumber" className="mb-3 mt-4 pt-3">
                  <Form.Label className="">Card Number</Form.Label>
                  <div id="card-number"></div>
                </Form.Group>

                <Form.Group controlId="expiryDate" className="mb-3 mt-4">
                  <Row className="mt-5">
                    <Col md={6} sm={12}>
                      <Form.Label className="">Expiry Date</Form.Label>
                      <div id="card-expiry"></div>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Label className="">CVC</Form.Label>
                      <div id="card-cvc"></div>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  controlId="cvc"
                  className="text-center h-auto w-100  mt-4 "
                >
                  {!loading ? (
                    <Button
                      variant="primary"
                      className="btn_credit_style w-100 p-3"
                      type="submit"
                    >
                      {" "}
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className="btn_credit_style w-100"
                    >
                      <PulseLoader color="#ffff" size={10} />
                    </Button>
                  )}
                </Form.Group>
                </Form>
    </div>
  );
}
export default CreditCardForm;
