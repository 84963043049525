import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../Sass/Header1.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { connectWallet, disconnectWallet } from "../Wallet/Connect";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Button } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import { FaRegUserCircle, FaUserCircle } from "react-icons/fa";
import setting from "../Assets/Images/setting.png";
import domains from "../Assets/Images/domains.png";
import wallet from "../Assets/Images/Wallet2.png";
import eco from "../Assets/Images/eco.png";
import premium from "../Assets/Images/premium.png";
import axios from "axios";

function Header({ loginHeader, navigate_back }) {
  const navigate = useNavigate();
  const getAddress = sessionStorage.getItem("wallet_address");
  const check_user_login=sessionStorage.getItem("is_logged_in");
  const [Wallet_address, set_wallet_address] = useState(null);
  const [walletStatus, setwalletStatus] = useState(false);
  const Api = localStorage.getItem("API");
  const LoginUser = sessionStorage.getItem("LoginUser");
  const Token = sessionStorage.getItem("Token");
  const Refresh = sessionStorage.getItem("RefreshToken");
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);
  console.log('Active Page ------ ', activePage)
  const [showMenu, setShowMenu] = useState(false);
  const [wallet_address_short, set_wallet_address_short] = useState("");
  const premiumMemberStatus = useSelector((state) => state.cirl.premium_member);
  const [user_is_premium, set_user_is_premium] = useState(premiumMemberStatus);
  const [userName,setUserName]=useState('')
  const User_Name=sessionStorage.getItem('userName');
  const premium_status = sessionStorage.getItem("is_premium");
  const get_premium_status =
    user_is_premium !== null || user_is_premium !== ""
      ? user_is_premium
      : premium_status;
  const set_premiumMemberStatus =
    premiumMemberStatus === true || premiumMemberStatus === "true"
      ? true
      : false;
  const [selectedOption, setSelectedOption] = useState(location.pathname);
  
  useEffect(() => {
    set_user_is_premium(premiumMemberStatus);
  }, [premiumMemberStatus]);

  useEffect(() => {
    if (getAddress && getAddress != "undefined") {
      set_wallet_address(getAddress);
      set_wallet_address_short(getAddress);
    } else {
      set_wallet_address(null);
      console.log("getAddress2", getAddress);
    }
  }, [Wallet_address]);

  const NavigateToHome = (props) => {
    navigate("/home");
    setSelectedOption(props);
    window.scrollTo(0, 0);
  };

  const HandlepremiumNavigation = (props) => {
    navigate("/premium");
    setSelectedOption(props);
    window.scrollTo(0, 0);
  };
  const HandleSubscriptionNavigation = (props) => {
    navigate("/subscription");
    setSelectedOption(props);
    window.scrollTo(0, 0);
  };

  const HandleLearnNavigation = (props) => {
    navigate("/learn");
    setSelectedOption(props);
    window.scrollTo(0, 0);
  };

  const HandleSupportNavigation = (props) => {
    navigate("/support");
    setSelectedOption(props);
    window.scrollTo(0, 0);
  };

  const HandleDomainNavigation = (props) => {
    setSelectedOption(props);

    navigate("/domains");
  };

  const HandleLogin = (props) => {
    setSelectedOption(props);
    navigate("/login");
  };

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  const HandleSettings = (props) => {
    navigate("/settings");
  };

  const HandlePremiumContent = () => {
    navigate("/premiumcontent");
  };

  const HandleChangePassword = () => {
    navigate("/changepassword");
  };

  const HandleLogout = () => {
    console.log("Token ----- ", Token);
    console.log("Refresh Token ----- ", Refresh);
    console.log("Api ----- ", Api);
    NotificationManager.success("Logout Successfully");
    sessionStorage.clear();
    navigate_back == "/login" ? navigate('/home') : navigate(navigate_back);
    sessionStorage.setItem("LoginUser", false);
    disconnectWallet();
    set_wallet_address(null);
  };

  const ConnectWallet = async (props) => {
    const signer = await connectWallet();
    const address = await signer.address.toLowerCase();
    console.log(address);
    if (address) {
      Check_wallet_valid(address,props);
    }
  };
  const Check_wallet_valid = (address,props) => {
    console.log('token1212',Token)
    axios
      .get(
        `${Api}/user_management/check_user_wallet_address/?wallet_address=${address}`,  Token !== null ?{
          headers: {
            Authorization: `Bearer ${Token}`,
          }
        }:''
      ).then((res) => {
        console.log('test api test 1',res);
        sessionStorage.setItem("is_logged_in",res.data.is_logged_in)
        if (res.data.is_user === true) {
          console.log('res.data.is_user',res.data.is_user)
          
          if (sessionStorage.getItem("LoginUser") === "true") {
            sessionStorage.setItem("wallet_address", address);
            set_wallet_address(address);
            setUserName(res.data.full_name)
            sessionStorage.setItem('userName',res.data.full_name)
          } else {
            sessionStorage.setItem("wallet_address", address);
            navigate("/WalletLogin",{state: {state: props}});
          }
        } else if (res.data.is_user === false) {
          sessionStorage.setItem("wallet_address", address);
          navigate("/WalletSignup",{state: {state: props}});
        } else {
          NotificationManager.error(res.data.error);
        }
      })
      .catch((error) => {
        NotificationManager.error('some thing went wrong');
        console.log("Login Api Error ----- ", error);
      })
      
  };

  const DisconnectWallet = () => {
    disconnectWallet();
    // window.dispatchEvent(new Event("storage"));
    set_wallet_address(null);
    // HandleLogout();
  };

  return (
    <div className="header1-maindiv">
      {["lg"].map((expand) =>
        loginHeader == true ? (
          <Navbar key={expand} expand={expand}>
            <Container fluid>
              <Navbar.Brand href="#">
                <span
                  onClick={() => NavigateToHome("/home")}
                  className={
                    selectedOption === "/updatecard"
                      ? "selected-option_header logo_text"
                      : "logo_text"
                  }
                >
                  Cirl.one
                </span>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title
                    className="logo_text"
                    id={`offcanvasNavbarLabel-expand-${expand}`}
                  >
                    <span
                      style={{
                        fontFamily: "Microgramma-D-Extended",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "45px",
                        lineHeight: "58px",
                        color: "#406aff",
                      }}
                      className="logo_text"
                    >
                      Cirl.one
                    </span>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body></Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ) : (
          <Navbar
            className="main-header"
            // className={scrolled ? "main-header fixed-nav px-5" : "main-header px-5" }
            key={expand}
            expand={expand}
          >
            <Container fluid>
              <Navbar.Brand href="#">
                <span
                  onClick={() => NavigateToHome("/home")}
                  className={
                    selectedOption === "/updatecard"
                      ? "selected-option_header logo_text"
                      : "logo_text"
                  }
                >
                  Cirl.one
                </span>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title
                    className="logo_text"
                    id={`offcanvasNavbarLabel-expand-${expand}`}
                  >
                    <span
                      style={{
                        fontFamily: "Microgramma-D-Extended",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "45px",
                        lineHeight: "58px",
                        color: "#406aff",
                      }}
                      className="logo_text"
                    >
                      Cirl.one
                    </span>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link
                      onClick={() => NavigateToHome("/home")}
                      className={
                        selectedOption === "/home"
                          ? "selected-option_header home"
                          : "home"
                      }
                      id="nav_custom_style"
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => HandlepremiumNavigation("/premium")}
                      className={
                        selectedOption === "/premium"
                          ? "selected-option_header premium"
                          : "premium"
                      }
                      id="nav_custom_style"
                    >
                      premium domains
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        HandleSubscriptionNavigation("/subscription")
                      }
                      className={
                        selectedOption === "/subscription"
                          ? "selected-option_header subscription"
                          : "subscription"
                      }
                      id="nav_custom_style"
                    >
                      Subscription
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => HandleLearnNavigation("/learn")}
                      className={
                        selectedOption === "/learn"
                          ? "selected-option_header learn"
                          : "learn"
                      }
                      id="nav_custom_style"
                    >
                      Learn
                    </Nav.Link>
                    <Nav.Link onClick={()=>HandleSupportNavigation('/support')}   className={selectedOption === '/support' ? 'selected-option_header support' : 'support'} id="nav_custom_style" >Support</Nav.Link>
                    {/* <Nav.Link
                      className={
                        selectedOption === "/support"
                          ? "selected-option_header support"
                          : "support"
                      }
                      id="nav_custom_style"
                    >
                      Support
                    </Nav.Link> */}
                    {/* <NavDropdown
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown> */}
                    {LoginUser == "true" ? (
                      null
                      // <li>
                      //   <Nav.Link  className='support' style={{cursor:"pointer", color:"#000", fontWeight:"700",textTransform:'capitalize',fontFamily:'Manrope Variable',marginRight:'0.8em',fontSize:'15px',lineHeight:'20.49px'}} onClick={()=>{HandleLogout()}}>Logout</Nav.Link>
                      // </li>
                    ) : (
                      <li>
                        <Nav.Link   className={selectedOption === '/login' ? 'selected-option_header login' : 'login'}  style={{cursor:"pointer", color:"#000", fontWeight:"700",textTransform:'capitalize',fontFamily:'Manrope Variable',marginRight:'0.8em',fontSize:'15px',lineHeight:'20.49px'}} onClick={()=>{HandleLogin('/login')}}>Login</Nav.Link>
                      </li>
                    )}
                    <Nav.Link>
                      <li>
                        {LoginUser == "true" && check_user_login == 'true' && getAddress ? (
                          <Button
                            variant="outlined"
                            title={getAddress}
                            id="btn_connect_wallet"
                            onClick={() => {
                              DisconnectWallet();
                            }}
                          >
                            Disconnect
                          </Button>
                        ) : (
                          // <Button variant='outlined'  id="btn_connect_wallet"  onClick={()=>{ConnectWallet()}}>Connect Wallet</Button>
                          <Button variant="outlined" id="btn_connect_wallet" onClick={()=>{ConnectWallet(activePage)}}>
                            Connect Wallet
                          </Button>
                        )}
                      </li>
                    </Nav.Link>

                    <Nav.Link>
                      {
                        // getAddress
                        LoginUser == "true" ? (
                          <>
                            <li>
                              <Dropdown
                                show={showMenu}
                                onMouseEnter={handleMenuToggle}
                                onMouseLeave={handleMenuToggle}
                                drop="down"
                              >
                                <Dropdown.Toggle
                                  variant="transparent"
                                  id="profile-icon"
                                  className="no-caret"
                                  drop="down"
                                >
                                  <FaRegUserCircle size={40} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  drop="down"
                                  className="dropdown_container dropdown_container_position"
                                  id="dropdown_container_positionid"
                                >
                                   <Dropdown.Item
                                      className="dropdown_items" 
                                      style={{textAlign: 'center', fontSize: '16px', color: 'black !important', padding: '0', margin: '0px 0 5px 0',borderBottom: '1px solid #909294'}}
                                    >
                                      {userName===''?User_Name:userName}
                                    </Dropdown.Item>
                                   {getAddress && check_user_login == 'true'? (
                                    <Dropdown.Item
                                      className="dropdown_items" 
                                    >
                                      <img
                                        className="dropdown_img"
                                        style={{filter:'invert(32%) sepia(95%) saturate(1135%) hue-rotate(211deg) brightness(103%) contrast(119%)'}}
                                        src={wallet}
                                      />{" "}
                                      {getAddress.slice(0, 6) + '.....' + getAddress.slice(-4)}
                                    </Dropdown.Item>
                                  ) : (
                                    ""
                                  )}
                                  {getAddress && check_user_login == 'true' ? (
                                    <Dropdown.Item
                                      className="dropdown_items"
                                      onClick={HandleDomainNavigation}
                                    >
                                      <img
                                        className="dropdown_img"
                                        src={domains}
                                      />{" "}
                                      My Domain
                                    </Dropdown.Item>
                                  ) : (
                                    ""
                                  )}
                                  {set_premiumMemberStatus && (
                                    <Dropdown.Item
                                      className="dropdown_items"
                                      onClick={HandlePremiumContent}
                                    >
                                      <img className="dropdown_img" src={eco} />{" "}
                                      Premium Content
                                    </Dropdown.Item>
                                  )}
                                  <Dropdown.Item
                                    className="dropdown_items"
                                    onClick={HandleChangePassword}
                                  >
                                    <img
                                      className="dropdown_img"
                                      src={premium}
                                    />{" "}
                                    Change Password
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdown_items"
                                    onClick={HandleSettings}
                                  >
                                    <img
                                      className="dropdown_img"
                                      src={setting}
                                    />{" "}
                                    Settings
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    className="dropdown_items_end"
                                    onClick={() => {
                                      HandleLogout();
                                    }}
                                  >
                                    {" "}
                                    Logout
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </li>
                          </>
                        ) : null
                      }
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        )
      )}
    </div>
  );
}

export default Header;
