import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51Mc1A9Chg18ijhGETvgTddMGzm9gy4uJ4iTlPO6P1vSJsbnctncCJlI7qmObPGbF0QBuC8sekMLp4DCb1IVfv2fa00tTJnPBx4');

const PaymentForm = () => {
  const [stripe, setStripe] = useState(null);
  const [cardNumberElement, setCardNumberElement] = useState(null);
  const [cardExpiryElement, setCardExpiryElement] = useState(null);
  const [cardCvcElement, setCardCvcElement] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);

      // Create the Stripe Elements
      const elements = stripeInstance.elements();

      const cardNumber = elements.create('cardNumber');
      cardNumber.mount('#card-number');
      setCardNumberElement(cardNumber);

      const cardExpiry = elements.create('cardExpiry');
      cardExpiry.mount('#card-expiry');
      setCardExpiryElement(cardExpiry);

      const cardCvc = elements.create('cardCvc');
      cardCvc.mount('#card-cvc');
      setCardCvcElement(cardCvc);
    };

    initializeStripe();
  }, []);

  const handleCardFormSubmit = async (event) => {
    event.preventDefault();

    if (stripe && cardNumberElement && cardExpiryElement && cardCvcElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
      });

      if (error) {
        console.log('Payment method error:', error);
      } else {
        console.log('Payment method:', paymentMethod);
      }
    }
  };

  return (
    <form onSubmit={handleCardFormSubmit}>
      <div id="card-number"></div>
      <div id="card-expiry"></div>
      <div id="card-cvc"></div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default PaymentForm;
