import WalletConnectprovider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";


export const providerOptions = {

  // walletlink: {
  //   package: CoinbaseWalletSDK, // Required
  //   options: {
  //     infuraId: "https://goerli.infura.io/v3/f7494942e54c448aa39ecad379737ade" // Required unless you provide a JSON RPC url; see `rpc` below
  //   }
  // },
  walletconnect: {
      package: WalletConnectprovider, // required
      options: {
        options: {
          infuraId: 'https://goerli.infura.io/v3/f7494942e54c448aa39ecad379737ade',
        },
          rpc: {
            56: 'https://bsc-dataseed1.binance.org'
          },
          bridge: "https://bridge.walletconnect.org",
          qrcodeModalOptions: {
            mobileLinks: [
              "rainbow",
              "metamask",
              "argent",
              "trust",
              "imtoken",
              "pillar",
            ],// 
      }
    }
  },
  // binancechainwallet: {
  //   package: true
  // },
  trustwallet:{
    package: true
  },
  metamask:{
    package: true
  }
};
