import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { useState } from "react";
import InputMask from "react-input-mask";
import { PulseLoader } from "react-spinners";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import "../Sass/Credit_Signup.scss";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { setPremiumMemberStatus } from "../cirl_redux/cirlSlice";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

function Credit_Signup() {
  const [loading, setLoading] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [cardNumberElement, setCardNumberElement] = useState(null);
  const [cardExpiryElement, setCardExpiryElement] = useState(null);
  const [cardCvcElement, setCardCvcElement] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordInput1, setPasswordInput1] = useState("");
  const getLoginStatus = sessionStorage.getItem("LoginUser");
  const [userName, setUserName] = useState("");
  const GetPakageId = useSelector((state) => state.cirl.subscription_plane_id);
  const Pakage_id =
    GetPakageId !== null ? GetPakageId : sessionStorage.getItem("planeID");
  const Dispatch = useDispatch();
  const Api = localStorage.getItem("API");
  const navigate = useNavigate();
  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const stripePromise = loadStripe(
    "pk_test_51MGeSgLN0C8PbbSaxqzV70km0tvzZeZ29amk7P6SMlHqS9H84fLYinRLvYHDle3d5zoMlFrUzdWofuqXfMsI0zAT00f3TmvCkB"
  );
  const validateInput = (e) => {
    let { name, value } = e.target;
    console.log("Name ---- ", name, value);
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            // stateObj[name] = "Please enter Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            // stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);

      // Create the Stripe Elements
      const elements = stripeInstance.elements();

      const cardNumber = elements.create("cardNumber");
      cardNumber.mount("#card-number");
      setCardNumberElement(cardNumber);

      const cardExpiry = elements.create("cardExpiry");
      cardExpiry.mount("#card-expiry");
      setCardExpiryElement(cardExpiry);

      const cardCvc = elements.create("cardCvc");
      cardCvc.mount("#card-cvc");
      setCardCvcElement(cardCvc);
    };

    initializeStripe();
  }, []);

  const handleCardFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    if (stripe && cardNumberElement && cardExpiryElement && cardCvcElement) {
      stripe
        .createToken(cardNumberElement)
        .then(function (result) {
          if (result.token.id) {
            const card_tok = result.token.id;
            console.log(card_tok);
            HandleSubmit(event, card_tok);
          } else {
            if (
              error ==
              "TypeError: Cannot read properties of undefined (reading 'id')"
            ) {
              NotificationManager.error("please enter correct card details");
              setTimeout(()=>{
                setLoading(false);
              },5000)
              
            } else {
              NotificationManager.error(error.message);
            }
          }
        })
        .catch((error) => {
          console.log("Payment method error:", error);
          if (
            error ==
            "TypeError: Cannot read properties of undefined (reading 'id')"
          ) {
            NotificationManager.error("please enter correct card details");
            setTimeout(()=>{
              setLoading(false);
            },5000)
          } else {
            NotificationManager.error(error.message);
            setTimeout(()=>{
              setLoading(false);
            },5000)
          }
        });
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
    console.log("Password field ----- ", evnt.target.value);
    onInputChange(evnt);
  };
  const togglePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const handlePasswordChange1 = (evnt) => {
    setPasswordInput1(evnt.target.value);
    onInputChange(evnt);
    console.log("Confirm Password field ----- ", evnt.target.value);
  };
  const togglePassword1 = () => {
    passwordType1 === "password"
      ? setPasswordType1("text")
      : setPasswordType1("password");
  };
  const HandleLogin = () => {
    navigate("/login");
  };

  const HandleSubmit = (event, Card_token) => {
    console.log("getLoginStatus", getLoginStatus === "true");
    event.preventDefault();

      setLoading(true);

    const data = new FormData(event.target);

    const body = {
      full_name: data.get("Fullname"),
      email: data.get("email"),
      password: data.get("password"),
      password2: data.get("confirmPassword"),
      card_token: Card_token,
      plan: Pakage_id,
    };

    axios
      .post(`${Api}/auth/web_user_register/`, body)
      .then((res) => {
        console.log("Signup Api Response ---- ", res.data);
        if (res.data.status == false) {
          if (
            res.data.error == "User Already Exists, Try Adding other username"
          ) {
            NotificationManager.error("This Username is Already Taken");
            setTimeout(()=>{
              setLoading(false);
            },5000)
          } else if (res.data.error == "Email must be Unique.") {
            NotificationManager.error(res.data.error);
                setTimeout(()=>{
              setLoading(false);
            },5000)
          } else if (
            res.data.error ==
            "Password must contain at least one uppercase letter.."
          ) {
            NotificationManager.error(res.data.error);
                setTimeout(()=>{
              setLoading(false);
            },5000)
          } else if (
            res.data.error ==
            "Password must contain at least one unique character."
          ) {
            NotificationManager.error(res.data.error);
            
                setTimeout(()=>{
              setLoading(false);
            },5000)
            
          } else {
            // event.target.reset(),
            NotificationManager.error(res.data.error);
                setTimeout(()=>{
              setLoading(false);
            },5000)
          }
        } else if (res.data.status == true) {
          sessionStorage.setItem("LoginUser", true);
          sessionStorage.setItem("Token", res.data.access);
          sessionStorage.setItem("RefreshToken", res.data.refresh);
          sessionStorage.setItem("is_premium", res.data.is_premium);
          setUserName(res.data.full_name);
          sessionStorage.setItem("userName", res.data.full_name);
          sessionStorage.setItem("CheckSubscription", true);
          Dispatch(setPremiumMemberStatus(res.data.is_premium));
          NotificationManager.success("Payment successfull");
          sessionStorage.setItem("is_premium", "true");
          navigate("/premiumcontent");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("Signup Api Error ----- ", error.response.data.email[0]);
       
        

        if (error.response.data.email[0]) {
          NotificationManager.error(error.response.data.email[0]);
          setTimeout(()=>{
            setLoading(false);
          },5000)
        }
        if (error.response.data.full_name[0]) {
          NotificationManager.error(error.response.data.full_name[0]);
          setTimeout(()=>{
            setLoading(false);
          },5000)
        }
        if (error.response.data.password[0]) {
          NotificationManager.error(error.response.data.password[0]);
          setTimeout(()=>{
            setLoading(false);
          },5000)
        }
        if (error.response.data.password2[0]) {
          NotificationManager.error(error.response.data.password2[0]);
          setTimeout(()=>{
            setLoading(false);
          },5000)
        }
        if (error.response.data.card_token[0]) {
          NotificationManager.error(error.response.data.card_token[0]);
          setTimeout(()=>{
            setLoading(false);
          },5000)
        }
        if (error.response.data.plan[0]) {
          NotificationManager.error(error.response.data.plan[0]);
          setTimeout(()=>{
            setLoading(false);
          },5000)
        } else {
          NotificationManager.error("some thing went wrong");
          setTimeout(()=>{
            setLoading(false);
          },5000)
        }

      });
  };

  const get_Card_data = (event) => {
    setLoading(true);
    event.preventDefault();
    console.log("get_Card_data here ");
    const data = new FormData(event.target);

    const parts = data.get("cardExp").split("/");
    const month = parts[0]; // "23"
    const year = parts[1]; // "2023"
    const cardNumberWithoutSpaces = data.get("cardNum").replace(/\s/g, "");
    const body2 = {
      card_number: cardNumberWithoutSpaces,
      cvc: data.get("cardCvv"),
      month: month,
      year: year,
    };
    const token_access = sessionStorage.getItem("Token");

    axios
      .post(`${Api}/payment/cardtoken/`, body2, {
        headers: {
          Authorization: `Bearer ${token_access}`,
        },
      })
      .then((res) => {
        console.log("cardtoken", res.data);
        if (res.data.error) {
          NotificationManager.error(res.data.error);
          setTimeout(()=>{
            setLoading(false);
          },5000)
          
        } else if (res.data.token.status === false) {
          NotificationManager.error("Please enter correct Credit Card number");
          setTimeout(()=>{
            setLoading(false);
          },5000)
        } else if (res.data.token.status === true) {
        }
      });
  };

  return (
    <>
      <Header loginHeader={false} />
      <Container className="credit_signup_container">
        <Form onSubmit={handleCardFormSubmit} className="credit_signup_form">
          <Row>
            <Col md={6} lg={6} sm={12} id="credit-right-padding">
              <div
                className="signup-container p-0"
                style={{ marginTop: "1.8em" }}
              >
                <h1 className="signup-h1">Sign Up</h1>
                <p className="signup-p pb-2">Please enter your details</p>
                <div className="mt-3" />
                <Form.Group className="mb-1" controlId="formBasicEmail">
                  <Form.Label className="signup-formlabel">
                    Full Name
                  </Form.Label>
                  <Form.Control
                    required
                    name="Fullname"
                    type="text"
                    placeholder="Full Name"
                    className="credit_signup_placeholder"
                  />
                </Form.Group>

                <Form.Group className="mb-1 mt-4" controlId="formBasicEmail">
                  <Form.Label className="signup-formlabel">
                    Email Address
                  </Form.Label>
                  <Form.Control
                    required
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    className="credit_signup_placeholder"
                  />
                </Form.Group>

                <Form.Group className="mb-1 mt-4" controlId="formBasicPassword">
                  <Form.Label className="signup-formlabel">Password</Form.Label>
                  <div className="signup-password-div">
                    <Form.Control
                      required
                      title="Password must contain one special character, one Uppercase and at least 8 characters length"
                      name="password"
                      placeholder="Password"
                      type={passwordType}
                      defaultValue={input.password}
                      onChange={handlePasswordChange}
                      onBlur={validateInput}
                      pattern="(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      className="credit_signup_placeholder"
                    />

                    {passwordType == "password" ? (
                      <FiEye onClick={togglePassword} />
                    ) : (
                      <FiEyeOff onClick={togglePassword} />
                    )}
                  </div>
                  <div className="signup-form-label-div">
                    <Form.Label className="signup-form-label text_info">
                      Password must contain one special character, one Uppercase
                      and at least 8 characters length
                    </Form.Label>{" "}
                    <br />
                    {error.password && (
                      <span style={{ color: "red" }}>{error.password}</span>
                    )}
                  </div>
                  {/* {state_Passmatch == true ? <p>Password match</p> : <p>Password must be same</p>} */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="signup-formlabel">
                    Confirm Password
                  </Form.Label>
                  <div className="signup-password-div">
                    <Form.Control
                      required
                      title="Password must contain one special character, one Uppercase and at least 8 characters length"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      type={passwordType1}
                      defaultValue={input.confirmPassword}
                      onChange={handlePasswordChange1}
                      onBlur={validateInput}
                      pattern="(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      className="credit_signup_placeholder"
                    />

                    {passwordType1 == "password" ? (
                      <FiEye onClick={togglePassword1} />
                    ) : (
                      <FiEyeOff onClick={togglePassword1} />
                    )}
                  </div>
                  <div className="signup-form-label-div">
                    <Form.Label className="signup-form-label text_info">
                      Password must contain one special character, one Uppercase
                      and at least 8 characters length
                    </Form.Label>
                    {error.confirmPassword && (
                      <span style={{ color: "red", marginTop: "-10px" }}>
                        {error.confirmPassword}
                      </span>
                    )}
                  </div>
                  {/* {state_Passmatch == true ? <p>Password match</p> : <p>Password must be same</p>} */}
                </Form.Group>
              </div>
            </Col>
            <Col md={6} lg={6} sm={12} id="credit-left-padding">
              <h1 className="signup-h1 mt-4">Credit Card Details</h1>
              <p className="signup-p">Please enter your details</p>
              <div className="mb-3 mt-4" />
              <div className="creditcard-form">
                <Form.Group controlId="cardNumber" className="mb-3 mt-4 pt-3">
                  <Form.Label className="">Card Number</Form.Label>
                  <div id="card-number"></div>
                </Form.Group>

                <Form.Group controlId="expiryDate" className="mb-3 mt-4">
                  <Row className="mt-5">
                    <Col md={6} sm={12}>
                      <Form.Label className="">Expiry Date</Form.Label>
                      <div id="card-expiry"></div>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Label className="">CVC</Form.Label>
                      <div id="card-cvc"></div>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  controlId="cvc"
                  className="text-center h-auto w-100  mt-4 "
                >
                  {!loading ? (
                    <Button
                      variant="primary"
                      className="btn_credit_style w-100 p-3"
                      type="submit"
                    >
                      {" "}
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className="btn_credit_style w-100 p-3"
                    >
                      <PulseLoader color="#ffff" size={10} />
                    </Button>
                  )}
                  <p className="mt-2 mb-2 signup-or">OR</p>
                  <Button
                    className="mt-3 mb-5 signup-sign-btn w-100  p-3"
                    onClick={HandleLogin}
                    variant="primary"
                    type="submit"
                  >
                    Already have an account? Login
                  </Button>
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
}

export default Credit_Signup;
