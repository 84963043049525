import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  premium_member: sessionStorage.getItem('is_premium'),
  subscription_plane_id : null
}

const cirlSlice = createSlice({
  name: 'cirl',
  initialState,
  reducers: {
    setPremiumMemberStatus: (state, action) => {
      state.premium_member = action.payload;
    },
    setsubscription_plane_id: (state, action) => {
      state.subscription_plane_id = action.payload;
    },
  },
});

export const { setPremiumMemberStatus,setsubscription_plane_id } = cirlSlice.actions;
export default cirlSlice.reducer;