import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import InputMask from "react-input-mask";
import { FaCreditCard, FaRegCreditCard } from "react-icons/fa";
import "../Sass/CreditCardForm.scss";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { PuffLoader, PulseLoader } from "react-spinners";
import card_img from "../Assets/Images/card_img.png";
import { useDispatch } from "react-redux";
import {
  getPremiumState,
  setPremiumMemberStatus,
} from "../cirl_redux/cirlSlice";
import { loadStripe } from '@stripe/stripe-js';


function UpdateCreditCardForm({ payment_success }) {

  const [stripe, setStripe] = useState(null);
  const [cardNumberElement, setCardNumberElement] = useState(null);
  const [cardExpiryElement, setCardExpiryElement] = useState(null);
  const [cardCvcElement, setCardCvcElement] = useState(null);
  const [cardEmail, setcardEmail] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const Api = localStorage.getItem("API");
  const token_access = sessionStorage.getItem("Token");
  const [loading, setLoading] = useState(false);
  var userLoginEmail = "";
  const Dispatch = useDispatch();

  const stripePromise = loadStripe('pk_test_51MGeSgLN0C8PbbSaxqzV70km0tvzZeZ29amk7P6SMlHqS9H84fLYinRLvYHDle3d5zoMlFrUzdWofuqXfMsI0zAT00f3TmvCkB');

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);

      // Create the Stripe Elements
      const elements = stripeInstance.elements();

      const cardNumber = elements.create('cardNumber');
      cardNumber.mount('#card-number');
      setCardNumberElement(cardNumber);

      const cardExpiry = elements.create('cardExpiry');
      cardExpiry.mount('#card-expiry');
      setCardExpiryElement(cardExpiry);

      const cardCvc = elements.create('cardCvc');
      cardCvc.mount('#card-cvc');
      setCardCvcElement(cardCvc);
    };

    initializeStripe();
  }, []);
 

  const handleCardemail = (event) => {
    setcardEmail(event.target.value);
  };
  const handleCardNumberChange = (event) => {
    const cardNumberWithoutSpaces = event.target.value.replace(/\s/g, "");
    setCardNumber(cardNumberWithoutSpaces);
  };

  const handleExpiryDateChange = (event) => {
    setExpiryDate(event.target.value);
  };

  const handleCvvChange = (event) => {
    setCvv(event.target.value);
  };
  const get_card_info = () => {
    axios
      .get(`${Api}/payment/get_card_info/`, {
        headers: {
          Authorization: `Bearer ${token_access}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          userLoginEmail = res.data.email;
        } else {
          NotificationManager.error("some thing went wrong");
        }
      });
  };

 

  const handleSubmit = (event) => {

    event.preventDefault();
    setLoading(true);

    if (stripe && cardNumberElement && cardExpiryElement && cardCvcElement) {
        stripe.createToken(cardNumberElement).then(function(result) {
          if(result.token.id){
            const card_tok=result.token.id
            // console.log(card_tok)
            HandleSubmit(card_tok)
          }
          else{
            NotificationManager.error('some thing went wrong');
          }
     
       
         
      }).catch((error)=>{
          console.log('Payment method error:', error);
          if(error == "TypeError: Cannot read properties of undefined (reading 'id')"){
            NotificationManager.error("please enter correct card details");
            setTimeout(()=>{
              setLoading(false);
            },5000)
            
          }
          else{
            NotificationManager.error(error.message);
          }
          // NotificationManager.error(error.message);
      })
    }
  };

  const HandleSubmit = (card_tok,event) => {
      get_card_info();
          const member_data = {
            card_token: card_tok,
          };
          axios
            .post(`${Api}/payment/update_card/`, member_data, {
              headers: {
                Authorization: `Bearer ${token_access}`,
              },
            })
            .then((res) => {
              console.log("create_memebership", res.data);

              if (res.data.error) {
                NotificationManager.error("Some thing went wrong");
                Dispatch(setPremiumMemberStatus(false));
                setTimeout(()=>{
                  setLoading(false);
                },5000)
              } else {
                NotificationManager.success("Card Updated Successfully");
                sessionStorage.setItem("is_premium", "true");
                Dispatch(setPremiumMemberStatus(true));
                payment_success(false);
                setTimeout(()=>{
                  setLoading(false);
                },5000)
              }
            });

            cardNumberElement.clear();
                cardExpiryElement.clear();
                cardCvcElement.clear()
       
                setTimeout(()=>{
                  setLoading(false);
                },5000)
     
  }


  return (
    <div className="credit-card-form">
      <div className="credit-card-header">
        <img src={card_img} alt="Credit Card" className="credit-card-img" />
      </div>
      <div className="text-center">
        <h3 className="credit-card-heading">Credit Card Details</h3>
      </div>
      <Form onSubmit={handleSubmit} id='form' className="creditcard-form">
        <Form.Group controlId="cardNumber">
          <Form.Label className="">Card Number</Form.Label>
          <div id="card-number"></div>
          {/* <InputMask
            className="form-control"
            mask="9999 9999 9999 9999"
            placeholder="9999 9999 9999 9999"
            value={cardNumber}
            onChange={handleCardNumberChange}
            required
          /> */}
        </Form.Group>

        <Form.Group controlId="expiryDate">
          <Row>
            <Col md={6} sm={12}>
              <Form.Label className="">Expiry Date</Form.Label>
              <div id="card-expiry"></div>
              {/* <InputMask
                className="form-control"
                mask="99/9999"
                placeholder="MM/YYYY"
                value={expiryDate}
                onChange={handleExpiryDateChange}
                required
              /> */}
            </Col>
            <Col md={6} sm={12}>
              <Form.Label className="">CVC</Form.Label>
              <div id="card-cvc"></div>
              {/* <InputMask
                className="form-control"
                mask="999"
                placeholder="Enter CVV"
                value={cvv}
                onChange={handleCvvChange}
                required
              /> */}
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="cvv" className="text-center h-auto w-100">
          {!loading ? (
            <Button
              variant="primary"
              className="btn_credit_style w-100"
              type="submit"
            >
              {" "}
              Submit
            </Button>
          ) : (
            <Button variant="primary" className="btn_credit_style w-100">
              <PulseLoader color="#ffff" size={10} />
            </Button>
          )}
        </Form.Group>
      </Form>
    </div>
  );
}
export default UpdateCreditCardForm;
